import { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, Modal, Row } from 'react-bootstrap'
import classnames from 'classnames'

import DefaultSpinner from '../layout/DefaultSpinner'
import OddButton from '../layout/OddButton'
import OddsModalHeader from '../layout/OddsModalHeader'
import Icon from '../utils/Icon'
import {
  fetcher, generateMatch,
  generateMatches, generateOddsData
} from '../../utils'

export default function OddsModal({ axios, settings, match, selectedOdds, isDarkTheme, onHide, onOddClick, isMobile, sport, isLive = false, socket }) {
  if (window) {
    const body = window.document.querySelector("body");
    body.className += isDarkTheme ? ' dark-mode' : ' light-mode';
  }

  const [loading, setLoading] = useState(true)
  const [odds, setOdds] = useState([])
  const [showField, setShowField] = useState(true)
  const [streamType, setStreamType] = useState(null)
  const [liveMatch, setLiveMatch] = useState(null)

  useEffect(() => {
    if (!socket) return

    socket.on('match_data', (match_data) => {
      const _match = generateMatch(match_data, settings, true)
      handleMatchOdds(_match)
    })

    return () => {
      socket.off('match_data')
    }
  }, [socket, settings])

  const matchTimes = [
    { key: 'fullTime', text: '90 min' },
    { key: 'firstTime', text: '1º T' },
    { key: 'secondTime', text: '2º T' },
    { key: 'players', text: 'Jogadores' },
  ]
  const [matchTime, setMatchTime] = useState('fullTime')

  function activeClass(key) {
    return (matchTime === key) ? 'active' : ''
  }

  const loadOdds = async () => {
    setLoading(true)

    if (isLive) {
      socket.emit('join_room', match.id_partida)

      return
    }

    if (match.cotacao_api_id == 0) {
      setLoading(false)

      const _matches = generateMatches([match], settings)
      handleMatchOdds(_matches[0])

      return
    }

    const data = {
      chave: match.id_partida,
      tipo: 'partida',
      principal: 0
    }

    fetcher(axios, '/api/jogos', data)
      .then((result) => {
        if (!result.data || !result.data.length) {
          setOdds([])
          setLoading(false)

          return
        }

        const _matches = generateMatches(result.data, settings)
        handleMatchOdds(_matches[0])
      })
  }

  const handleMatchOdds = (data) => {
    const _odds = generateOddsData(data, isLive, Object.values(settings.categorias))

    setLiveMatch(data)
    setOdds(_odds)
    setLoading(false)
  }

  const handleEntering = (node) => {
    if (isMobile) {
      node.style.paddingLeft = '0px'
    }
  }

  const handleEnter = async () => {
    await loadOdds()
  }

  const handleHide = () => {
    setStreamType(null)
    setLiveMatch(null)

    if (isLive && socket) {
      socket.emit('exit_room', match.id_partida)
    }

    onHide()
  }

  const handleOddClick = (event) => {
    event()
    handleHide()
  }

  const renderOdds = () => {
    if (loading) {
      return <DefaultSpinner isDarkTheme={isDarkTheme} />
    }

    if (matchTime === 'players') {
      const categories = Object.keys(odds.players || {})

      if (!odds.players || !categories || !categories.length) {
        return (
          <div className={classnames(["d-flex flex-column justify-content-center align-items-center", { 'h-100': !isMobile, 'text-white': isDarkTheme }])} style={{ height: '80vh' }}>
            <div className="text-center" style={{ fontSize: '3rem' }}>
              <Icon name="meh" className="mt-5" />
              <p className="mb-5" style={{ fontSize: '1rem' }}>
                Sem cotações de jogadores para o jogo
              </p>
            </div>
          </div>
        )
      }

      return (
        categories.map((category) => {
          const categoryOdds = odds.players[category].odds
          const firstOdd = Object.values(categoryOdds)[0]
          const { first: hasFirst, last: hasLast, anytime: hasAnytime } = firstOdd

          return (
            <div key={category}>
              <div className="item">
                <Row>
                  <Col xs={12} className="p-2 bg-theme-color text-uppercase d-flex align-items-center justify-content-between">
                    <div className={classnames(['pl-0', { 'mr-4': !firstOdd.anytime, 'mr-5': !isMobile && firstOdd.anytime, 'col-5': isMobile && !firstOdd.anytime, 'col-6': !isMobile && !firstOdd.anytime, 'col-auto': !isMobile && firstOdd.anytime }])}>
                      <Icon name="caret-right" margin />
                      {odds.players[category].categoria}
                    </div>
                    {[hasFirst, hasLast, hasAnytime].map((oddType, i) => (
                      oddType && <small key={oddType.id_tipo} className={classnames(['col-3 text-center', {
                        'pr-0': isMobile && i === 0,
                        'p-0': isMobile && i > 0,
                        'pl-2': !isMobile && oddType.anytime && (i != 1)
                      }])}>
                        {oddType.tipo}
                      </small>
                    ))}
                  </Col>
                </Row>
              </div>

              {Object.keys(categoryOdds).map((id) => {
                const { first, last, anytime } = categoryOdds[id]

                return (
                  <div key={id} className="item">
                    <Row className="py-2 align-items-center">
                      <Col xs={isMobile ? (anytime ? 3 : 6) : (anytime ? 4 : 7)} className={classnames(['pl-2 pr-0', { 'mr-1': isMobile, 'text-light': isDarkTheme }])}>
                        {(first || last || anytime).jogador}
                      </Col>
                      <Col xs={2} className={classnames(['p-0', { 'mr-3': isMobile, 'ml-3': isMobile && !anytime, 'ml-4': isMobile && anytime, 'mr-4': !isMobile }])}>
                        {first && <OddButton match={match} odd={first} selectedOdds={selectedOdds} onClick={() => handleOddClick(() => onOddClick(match, first))} isMobile={isMobile} settings={settings} />}
                      </Col>
                      <Col xs={2} className={classnames(['p-0', { 'ml-2': isMobile, 'mr-2': isMobile && anytime, 'ml-4': !isMobile, 'mr-4': anytime }])}>
                        {last && <OddButton match={match} odd={last} selectedOdds={selectedOdds} onClick={() => handleOddClick(() => onOddClick(match, last))} isMobile={isMobile} settings={settings} />}
                      </Col>
                      {anytime && <Col xs={2} className={classnames(['p-0', { 'ml-3': !isMobile }])}>
                        <OddButton match={match} odd={anytime} selectedOdds={selectedOdds} onClick={() => handleOddClick(() => onOddClick(match, anytime))} isMobile={isMobile} settings={settings} />
                      </Col>}
                    </Row>
                  </div>
                )
              }
              )}
            </div>
          )
        })
      )
    }

    return (
      odds[matchTime] && odds[matchTime].map(category => (
        <div key={category.id}>
          <div className="item">
            <Row>
              <Col xs={12} className="p-2 bg-theme-color text-truncate text-uppercase">
                <Icon name="caret-right" margin /> {category.name}
              </Col>
            </Row>
          </div>
          {category.odds.map(odd => (
            <div key={odd.id_tipo} className="item">
              <Row className="p-2">
                <Col xs={8} className={classnames(['lh-38 text-truncate', { 'text-light': isDarkTheme }])}>{odd.tipo}</Col>
                <Col xs={4} className="px-0">
                  <OddButton match={match} odd={odd} selectedOdds={selectedOdds} onClick={() => handleOddClick(() => onOddClick(match, odd))} isMobile={isMobile} settings={settings} />
                </Col>
              </Row>
            </div>
          ))}
        </div>
      ))
    )
  }

  const renderTabs = () => {
    if (isLive) {
      return (
        <>
          <Button
            variant="secondary"
            className="rounded-0 text-uppercase col active custom-disabled">
            Ao Vivo
          </Button>
          {match.livestream && settings.mostrar_campo &&
            <Button
              variant="secondary"
              className="rounded-0 text-uppercase col"
              onClick={() => setShowField(val => !val)}>
              {showField ? 'Esconder' : 'Mostrar'} Campo
            </Button>}
        </>
      )
    }

    return matchTimes.map(time => (
      <Button
        key={time.key}
        variant="secondary"
        className={['rounded-0 text-uppercase col', activeClass(time.key)].join(' ')}
        onClick={() => setMatchTime(time.key)}>
        {time.text}
      </Button>
    ))
  }

  const renderStreams = (match) => {
    if (!settings.mostrar_campo || (!match.livestream && !match.tvstream)) {
      return null
    }

    if (!streamType && match.livestream && match.tvstream) {
      return <div className="d-flex flex-column justify-content-around h-100">
        <div className={classnames(['d-flex flex-column align-items-center text-white', { 'p-4': !isMobile, 'p-2 mb-3': isMobile }])} onClick={() => setStreamType('livestream')} style={{ cursor: 'pointer' }}>
          <h5 className="d-flex flex-column text-center">
            <Icon name="chart-area" className="mb-1" />
            Mini campo
          </h5>
          <span className={classnames(['text-primary', { 'small': isMobile }])}>
            Aperte aqui para acompanhar o jogo ao vivo
          </span>
        </div>
        <div className={classnames(['d-flex flex-column align-items-center text-white', { 'p-4': !isMobile, 'p-2': isMobile }])} onClick={() => setStreamType('tvstream')} style={{ cursor: 'pointer' }}>
          <h5 className="d-flex flex-column text-center">
            <Icon name="tv-retro" className="mb-1" />
            Televisão
          </h5>
          <span className={classnames(['text-primary', { 'small': isMobile }])}>
            Aperte aqui para assistir o jogo ao vivo
          </span>
        </div>
      </div>
    }

    const source = streamType ? match[streamType] : (match.livestream || match.tvstream)

    if (source) {
      return <div className={classnames(['widget-field-soccer', { 'mobile': isMobile }, { 'hide': !showField }])}>
        <iframe src={source} frameBorder="0" scrolling="no" allowtransparency="true" allow="autoplay" allowFullScreen></iframe>
      </div>
    }
  }

  return (
    <Modal className={classnames('odds-modal', { 'odds-modal-statistics': !isMobile && match && match.estatisticas })} show={!!match} onHide={() => handleHide()} onEnter={() => handleEnter()} onEntering={handleEntering} centered>

      {match &&
        <>
          <Modal.Header closeButton className={classnames('text-white', { 'h-auto': !!match.estatisticas })}>
            <OddsModalHeader
              match={isLive && liveMatch ? liveMatch : match}
              settings={settings}
              isLive={isLive}
            />
          </Modal.Header>
          {sport === 1 ? <ButtonGroup className="w-100 m-top--1">
            {renderTabs()}
          </ButtonGroup>
            : null}
          <div className="modal-scroll">
            {renderStreams(isLive && liveMatch ? liveMatch : match)}
            <div className="container h-100">
              {renderOdds()}
            </div>
          </div>
        </>
      }
    </Modal>
  )
}
