import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import MaskedInput from 'next-maskedinput'
import classnames from 'classnames'

import Icon from '../utils/Icon'
import { fetcher } from '../../utils'

export default function ModalUserBank({
  axios,
  show,
  settings,
  userAccount,
  isDarkTheme,
  onSubmitSuccess,
  onError,
  onHide,
}) {
  const userHasBank = !!userAccount
  const [bank, setBank] = useState((userHasBank && userAccount.banco_id) || '')
  const [agency, setAgency] = useState((userHasBank && userAccount.agencia) || '')
  const [account, setAccount] = useState((userHasBank && userAccount.conta) || '')
  const [accountType, setAccountType] = useState((userHasBank && userAccount.tipo_id) || '')
  const [owner, setOwner] = useState((userHasBank && userAccount.titular) || '')
  const [cpf, setCpf] = useState((userHasBank && userAccount.titular_cpf) || '')

  if (window && show) {
    const body = window.document.querySelector("body");
    body.className += isDarkTheme ? ' dark-mode' : ' light-mode';
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      banco: bank,
      agencia: agency.replace(/_/g, ''),
      conta: account.replace('-', '').replace(/_/g, ''),
      tipo_conta: accountType,
      titular: owner,
      titular_cpf: cpf.replace('-', '').replace(/\./g, '')
    }
    fetcher(axios, '/api/conta-banco', data)
      .then(({data}) => {
        if (data.resposta) {
          onSubmitSuccess()
        } else {
          alertify.alert(data.mensagem, data.descricao)
        }
      }).catch(onError)
  };

  return (
    <Modal show={show} centered onSubmit={handleSubmit}>
      <Modal.Header className={classnames(['pt-2 pb-2', { 'bg-dark': isDarkTheme }])}>
        <Modal.Title className={classnames(['display-5', { 'text-white': isDarkTheme }])}>
          Conta Bancária
        </Modal.Title>
        <Button variant={isDarkTheme ? 'dark' : 'light'} className={classnames(['text-right mt-1', { 'text-white': isDarkTheme }])} onClick={() => onHide()}>
          <Icon name="times" />
        </Button>
      </Modal.Header>
    <Form>
      <Modal.Body>
        <div className="d-block">
          <Form.Group>
            <Form.Label className={classnames({ 'text-white': isDarkTheme })}>Banco</Form.Label>
            <Form.Control
              as="select"
              className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
              value={bank}
              onChange={({target}) => setBank(target.value)}
              required
            >
              <option value="">Escolha um banco</option>
              {settings.bancos.map(banco => <option key={banco.id} value={banco.id}>{banco.codigo} - {banco.nome}</option>)}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="d-block">
          <Form.Group>
            <Form.Label className={classnames({ 'text-white': isDarkTheme })}>Agência</Form.Label>
            <Form.Control
              as={MaskedInput}
              className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
              placeholder="Informe o valor"
              value={agency}
              onChange={({target}) => setAgency(target.value)}
              mask="1111"
              required
            />
          </Form.Group>
        </div>
        <div className="d-block">
          <Form.Group>
            <Form.Label className={classnames({ 'text-white': isDarkTheme })}>Conta</Form.Label>
            <Form.Control
              as={MaskedInput}
              className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
              placeholder="Informe o valor"
              value={account}
              onChange={({target}) => setAccount(target.value)}
              mask="111111111111-1"
              required
            />
          </Form.Group>
        </div>
        <div className="d-block">
          <Form.Group>
            <Form.Label className={classnames({ 'text-white': isDarkTheme })}>Tipo de Conta</Form.Label>
            <Form.Control
              as="select"
              className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
              onChange={({target}) => setAccountType(target.value)}
              value={accountType}
              required
            >
              <option value="">Escolha um tipo de conta</option>
              {settings.contas_tipos.map(tipo => <option key={tipo.id} value={tipo.id}>{tipo.nome}</option>)}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="d-block">
          <Form.Group>
            <Form.Label className={classnames({ 'text-white': isDarkTheme })}>Titular da Conta</Form.Label>
            <Form.Control
              className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
              placeholder="Informe o nome do(a) titular"
              value={owner}
              onChange={({target}) => setOwner(target.value)}
              required
            />
          </Form.Group>
        </div>
        <div className="d-block">
          <Form.Group>
            <Form.Label className={classnames({ 'text-white': isDarkTheme })}>CPF do(a) titular</Form.Label>
            <Form.Control
              as={MaskedInput}
              className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme, 'bg-light': !isDarkTheme }])}
              placeholder="Informe o cpf do(a) titular"
              value={cpf}
              onChange={({target}) => setCpf(target.value)}
              mask="111.111.111-11"
              required
            />
          </Form.Group>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="success">Salvar</Button>
          <Button onClick={() => onHide()} variant="danger">Cancelar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
