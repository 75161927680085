import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { fetcher } from "../../utils/pages";
import BannersCarrousel from "../layout/BannersCarrousel";
import CardBanners from "./CardBanners";
import CasinoFavorites from "./CasinoFavorites";
import CategoriesCards from "./CategoriesCards";

const LandingPage = ({
  axios,
  isDarkTheme,
  isMobile,
  refreshBalance,
  sports,
  setPage,
  setSport,
  userLevel,
  hasCasino,
  hasLive,
  hasDemo,
  banners,
}) => {
  const [games, setGames] = useState<any>(null);

  useEffect(() => {
    if (window !== undefined) {
      const body = window?.document?.querySelector("body");

      if (!window?.document?.querySelector(".modal")) {
        body?.setAttribute("style", "overflow-y: scroll !important");
        body?.setAttribute("class", "overflow-scroll");
      }

      if (body?.getAttribute("style")?.includes("overflow-y: hidden;") || body?.classList?.contains("overflow-hidden")) {
        body?.setAttribute("style", "overflow-y: scroll !important");
        body?.setAttribute("class", "overflow-scroll");
      }
    }

    const getGames = async () => {
      const { data } = await fetcher(axios, "/api/jogos_cassino?favoritos=1");
      setGames(data);
    };

    getGames();
  }, []);

  if (window !== undefined) {
    const body = window?.document?.querySelector("body");

    if (!window?.document?.querySelector(".modal")) {
      body?.setAttribute("style", "overflow-y: scroll !important");
      body?.setAttribute("class", "overflow-scroll");
    }

    if (body?.getAttribute("style")?.includes("overflow-y: hidden;") || body?.classList?.contains("overflow-hidden")) {
      body?.setAttribute("style", "overflow-y: scroll !important");
      body?.setAttribute("class", "overflow-scroll");
    }
  }

  return (
    <Container as="main">
      <Row as="section" className="mt-4 px-3">
        <BannersCarrousel
          setPage={setPage}
          setSport={setSport}
          banners={
            banners?.length > 0
              ? banners
              : [
                {
                  id: "1",
                  page: "casino",
                  caminho:
                    "../../../images/landingPage/bannerIndexCasino.png",
                },
                {
                  id: "2",
                  page: "live",
                  caminho:
                    "../../../images/landingPage/bannerIndexSports.gif",
                },
              ]
          }
        />
      </Row>
      {sports
        && Object.keys(sports).length > 1
        && <CategoriesCards
          sports={sports}
          hasCasino={hasCasino}
          hasLive={hasLive}
          setPage={setPage}
          setSport={setSport}
          isDarkTheme={isDarkTheme}
        />}
      <CardBanners
        hasCasino={hasCasino}
        hasLive={hasLive}
        setPage={setPage}
        setSport={setSport}
        isDarkTheme={isDarkTheme}
      />
      <CasinoFavorites
        games={games?.jogos}
        userLevel={userLevel}
        hasDemo={hasDemo}
        setPage={setPage}
        setSport={setSport}
        axios={axios}
        isMobile={isMobile}
        isDarkTheme={isDarkTheme}
        refreshBalance={refreshBalance}
      />
    </Container>
  );
};

export default LandingPage;
