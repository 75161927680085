import { Button, Modal, Nav, Tab, Table } from "react-bootstrap";
import classnames from 'classnames'

import Icon from '../utils/Icon'
import { formatNumber } from '../../utils'

export default function CommissionsModal({ show, onHide, commissions, isDarkTheme }) {
  if (window && show) {
    const body = window.document.querySelector("body");
    body.className += isDarkTheme ? ' dark-mode' : ' light-mode';
  }

  const getLabel = (index, length) => {
    return index < length - 1 ?
      `${index + 1} jogo${index > 0 ? 's' : ''}` :
      `${index + 1} ou mais...`
  }

  return (
    <Modal show={show} centered>
      <Modal.Header className={classnames(['py-2', { 'bg-dark': isDarkTheme }])}>
        <Modal.Title className={classnames('display-5', { 'text-white': isDarkTheme })}>
          Minhas Comissões
        </Modal.Title>
        <Button variant={isDarkTheme ? 'dark' : 'light'} className={classnames(['text-right mt-1', { 'text-white': isDarkTheme }])} onClick={onHide}>
          <Icon name="times" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container defaultActiveKey="pre-jogo">
          <Nav variant="pills" className="nav-justified py-2">
            <Nav.Item className={classnames({ 'bg-dark': isDarkTheme })}>
              <Nav.Link eventKey="pre-jogo">
                Pré-jogo
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={classnames({ 'bg-dark': isDarkTheme })}>
              <Nav.Link eventKey="ao-vivo">
                Ao Vivo
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="pre-jogo">
              <Table variant={isDarkTheme ? 'dark' : ''} striped>
                <tbody>
                  {commissions.pre_jogo.map((commission, i) => (
                    <tr>
                      <td colspan="8">
                        Comissão {getLabel(i, commissions.pre_jogo.length)} ({commission.percentual}%)
                      </td>
                      <td colspan="4" align="right">
                        R$ {formatNumber(commission.valor)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab.Pane>
            <Tab.Pane eventKey="ao-vivo">
              <Table variant={isDarkTheme ? 'dark' : ''} striped>
                <tbody>
                  {commissions.ao_vivo.map((commission, i) => (
                    <tr>
                      <td colspan="8">
                        Comissão {getLabel(i, commissions.ao_vivo.length)} ({commission.percentual}%)
                      </td>
                      <td colspan="4" align="right">
                        R$ {formatNumber(commission.valor)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  )
}
